import dynamic from 'next/dynamic'
import NextHead from 'next/head'
import { useEffect, useMemo, useState } from 'react'
import { getComponentData, keysSnakeToCamel } from '@components/helper'
import { useUI } from '@components/ui/context'
import Script from 'next/script'
import Cookies from 'js-cookie'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { getURLParameter } from '@lib/utils/tools'
import s from './index.module.css'
import HesBanner from './component/HesBanner'
import HesSwiperBanner from '@components/banner/HesSwiperBanner'
const Quiz = dynamic(() => import('../../../components/home/Quiz'))
const PeaceOfMind = dynamic(() =>
  import('../../../components/home/PeaceOfMind')
)
const AdvantagePowerHouse = dynamic(() =>
  import('../../../components/home/AdvantagePowerHouse')
)
const Advantage = dynamic(() => import('../../../components/home/Advantage'))
const News = dynamic(() => import('../../../components/home/News'))
const Banner = dynamic(() => import('@components/banner/HomeBanner'))
const HotSpots = dynamic(() => import('@components/home/HotSpots'))
const RecallNavigation = dynamic(() =>
  import('../HomeCharger/RecallNavigation')
)
const Media = dynamic(() => import('../../../components/home/Media'))
const Pop = dynamic(() => import('../PPSDeals/components/Banner/Pop'))
const Firework = dynamic(() => import('@components/product/Firework/index'))
const ProductSeries = dynamic(() => import('../Solix/components/ProductSeries'))
const EnergySaving = dynamic(() => import('./component/EnergySaving'))
const SceneGuide = dynamic(() => import('./component/SceneGuide'))
const NewRightsArea = dynamic(() => import('./component/NewRightsArea'))
const SellRecommend = dynamic(() => import('./component/SellRecommend'))
const RightsArea = dynamic(() => import('./component/RightsArea'))
const Endorsement = dynamic(() => import('./component/Endorsement'))
const BrandStory = dynamic(() => import('./component/BrandStory'))
const Blogs = dynamic(() => import('./component/Blogs'))
const SoFunktioniert = dynamic(() => import('./component/SoFunktioniert'))
const BestChoice = dynamic(() => import('./component/BestChoice'))
const Explore = dynamic(() => import('./component/Explore'))
const NewHesSwiperBanner = dynamic(() => import('./component/HesSwiperBanner'))
const HomeSolutions = dynamic(() => import('./component/HomeSolutions'))
const CoreCompetitiveness = dynamic(() =>
  import('./component/CoreCompetitiveness')
)
const StayConnected = dynamic(() => import('./component/StayConnected'))
const NewBlogs = dynamic(() => import('@components/common/NewBlogs'))
const Serve = dynamic(() => import('./component/Serve'))
const AlwaysHelp = dynamic(() => import('./component/AlwaysHelp'))
const PowerfulSuite = dynamic(() => import('./component/PowerfulSuite'))
const NewMedia = dynamic(() => import('./component/NewMedia'))
const AniAmalgamate = dynamic(() => import('./component/AniAmalgamate'))

// start_ai_generated
function appendQueryParams(url, params) {
  const { setRelativeLink } = useRelativeLink()
  const rUrl = setRelativeLink({ link: url })
  const delimiter = rUrl?.includes('?') ? '&' : '?'
  return rUrl + delimiter + params
}

export { appendQueryParams }
// end_ai_generated

const HomePps = ({ page, relatedProducts, metafields, locale, pageType }) => {
  const { component_order, smoothScroll, shopCommon } = metafields
  const pageHandle = page?.handle

  const [precisoShow, setPrecisoShow] = useState(false)
  const [abtest, setAbtest] = useState(false)

  const { menuTheme, setMenuTheme } = useUI()
  const componentsDom = useMemo(() => {
    if (component_order) {
      return component_order.map((dataKey, i) => {
        const { key: componentKey, data } = getComponentData(
          metafields,
          dataKey
        )
        if (i === 0 && data?.theme) {
          setMenuTheme(data?.theme?.toLowerCase())
        }
        return {
          componentKey,
          data,
          dataKey,
        }
      })
    }
    return []
  }, [])
  // console.log('componentsDom:', componentsDom)
  useEffect(() => {
    if (locale == 'fr' || locale == 'eu-en' || locale == 'ca') {
      setPrecisoShow(true)
    } else if (locale == 'us' || locale == 'eu-de' || locale == 'uk') {
      if (Cookies.get('ref_ads')?.indexOf('utm_source=preciso') >= 0) {
        setPrecisoShow(true)
        return false
      }
      if (Math.random() < 0.5) {
        setPrecisoShow(true)
      }
    }
  }, [])
  const getProduct = (handle) => {
    return relatedProducts?.find(
      (p) => handle?.toLowerCase() === p?.result?.handle?.toLowerCase()
    )?.result
  }
  // useEffect(() => {
  //   const testList = componentsDom.reduce((ac, cu) => {
  //     const { dataKey, componentKey } = cu
  //     if (dataKey.includes('abtest')) {
  //       if (ac?.[componentKey]) {
  //         ac[componentKey].push(dataKey)
  //       } else {
  //         ac[componentKey] = [dataKey]
  //       }
  //     }
  //     return ac
  //   }, {})
  //   console.log('testList------', testList)
  //   const hostUrl = window.location.host.split('.')
  //   const domain =
  //     hostUrl.length > 2
  //       ? `.${hostUrl[1]}.${hostUrl[2]}`
  //       : window.location.hostname

  //   const ab = {}
  //   Object.keys(testList).forEach((key) => {
  //     const coo = Cookies.get(`abtest_${key}`)
  //     if (coo) {
  //       ab[key] = coo
  //     } else {
  //       const current = getRandomValueFromArray(testList[key])
  //       ab[key] = current
  //       Cookies.set(`abtest_${key}`, current, {
  //         domain,
  //         expires: 1,
  //       })
  //     }
  //   })
  //   setAbtest(ab)
  // }, [])

  useEffect(() => {
    const cov = getURLParameter('cov', window.location.href)
    if (cov === 'b') {
      setAbtest(true)
    } else {
      setAbtest(false)
    }
  }, [])

  const lcpImg = componentsDom
    ?.filter((item) => item.componentKey === 'hesBanner')?.[0]
    ?.data?.img?.replace('.png?', '_3840x.png?')

  const lcpImgMob = componentsDom
    ?.filter((item) => item.componentKey === 'hesBanner')?.[0]
    ?.data?.mobImg?.replace('.png?', '_750x.png?')

  return (
    <>
      <NextHead>
        {lcpImg && (
          <link
            rel="preload"
            href={lcpImg}
            as="image"
            // fetchpriority="hight"
          />
        )}
        {lcpImgMob && (
          <link
            rel="preload"
            href={lcpImgMob}
            as="image"
            // fetchpriority="hight"
          />
        )}
      </NextHead>
      {precisoShow && (
        <Script
          type={'text/javascript'}
          id="glb"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.data_4259 = [];
            window.data_4259.push({
            'gdpr': 0,
            'gdpr_consent': 'GDPR_CONSENT_874',
            'gdpr_pd': 0,
            'us_privacy': '',
            'PageType': 'home'
            });
            (function(d) {
              var s = d.createElement('script'); s.async = true;s.id='DLFNPMMP1';s.type='text/javascript';
              s.src ='https://cdn.preciso.net/aud/clientjs/ptag.js?4259';
              var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
            }(document));
          `,
          }}
        />
      )}

      <div className={s.root}>
        <div className={s.main}>
          {componentsDom.map(({ dataKey, componentKey, data }, i) => {
            if (
              dataKey.includes('abtest') &&
              abtest?.[componentKey] !== dataKey
            ) {
              return ''
            }
            switch (componentKey) {
              case 'newMedia':
                return (
                  <NewMedia
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'serve':
                return (
                  <Serve
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                    abtest={abtest}
                  />
                )
              case 'powerfulSuite':
                return (
                  <PowerfulSuite
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'alwaysHelp':
                return (
                  <AlwaysHelp
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'explore':
                return (
                  <Explore
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                    abtest={abtest}
                  />
                )
              case 'hesBanner':
                return (
                  <HesBanner
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'hesSwiperBanner':
                return (
                  <NewHesSwiperBanner
                    data={data}
                    id={dataKey}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`ppsHome-${dataKey}`}
                    abtest={false}
                  />
                )
              case 'homeBanner':
                return (
                  <Banner
                    gtmRef={`pps_masterBanner${i + 1}`}
                    lang={data}
                    product={getProduct(data?.handle)}
                    id={dataKey}
                    key={`ppsHome-${dataKey}`}
                    pageType={pageType}
                  />
                )
              case 'swiperBanner':
                return (
                  <HesSwiperBanner
                    gtmRef={`pps_masterBanner${i + 1}`}
                    relatedProducts={relatedProducts}
                    lang={data}
                    id={dataKey}
                    key={`${dataKey}-${i}`}
                    pageType={pageType}
                  />
                )
              case 'bestChoice':
                // if (abtest && dataKey === 'bestChoice') return null
                return (
                  <BestChoice
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`${dataKey}-${i}`}
                    abtest={abtest}
                  />
                )
              case 'coreCompetitiveness':
                return (
                  <CoreCompetitiveness
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'aniAmalgamate':
                return (
                  <AniAmalgamate
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`${dataKey}-${i}`}
                    abtest={abtest}
                  >
                    {/* {abtest && (
                      <BestChoice
                        data={{
                          ...getComponentData(metafields, 'bestChoice')?.data,
                          delPaddingTop: true,
                          delPaddingBottom: true,
                        }}
                        id={'bestChoice_B'}
                        locale={locale}
                        pageType={pageType}
                        pageHandle={pageHandle}
                        key={`${dataKey}-${i}-versionB`}
                        abtest={false}
                      />
                    )} */}
                  </AniAmalgamate>
                )
              case 'newRightsArea':
                return (
                  <NewRightsArea
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`${dataKey}-${i}`}
                    abtest={abtest}
                  />
                )
              case 'homeSolutions':
                return (
                  <HomeSolutions
                    data={data}
                    id={dataKey}
                    locale={locale}
                    pageType={pageType}
                    pageHandle={pageHandle}
                    key={`${dataKey}-${i}`}
                    abtest={abtest}
                  />
                )
              case 'hotSpotsList':
                return (
                  <HotSpots
                    data={data}
                    indexList={i}
                    id={dataKey}
                    key={dataKey}
                    pageType={pageType}
                    metafields={metafields}
                  />
                )
              case 'recallNavigation':
                return (
                  <RecallNavigation
                    data={data}
                    id={dataKey}
                    indexList={i}
                    key={`${dataKey}-${i}`}
                  />
                )
              case 'peaceOfMind':
                return (
                  <PeaceOfMind
                    indexList={i}
                    data={data}
                    id={dataKey}
                    key={`ppsHome-${dataKey}`}
                    pageType={pageType}
                  />
                )
              case 'homeMedia':
                return (
                  <Media
                    indexList={i}
                    data={data}
                    id={dataKey}
                    gs={
                      data?.newStyle
                        ? {
                            media_content_box_all: '!bg-white !pt-0',
                            titleBoxPadding: 'md:!pt-[72px] md:!pb-[32px]',
                            title_box: '!bg-white',
                            media_layer: '!bg-white',
                            media_content_box_all_grid:
                              '!bg-[#F5F5F7] !py-[60px] xl:!py-[40px]',
                            media_content_all_grid: '!bg-[#F5F5F7]',
                            subTitle:
                              '!text-center !text-[56px] !font-semibold !leading-[60px] !text-black md:!text-left xl:!text-[40px] xl:!leading-[48px]',
                            txtListNavItemBar: 'var(--solix-linear-color)',
                          }
                        : {
                            txtListNavItemBar: 'var(--solix-linear-color)',
                          }
                    }
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'quizVideo':
                return (
                  <Quiz
                    pageType={pageType}
                    indexList={i}
                    data={data}
                    id={dataKey}
                    pageHandle={page?.handle}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'advantage':
                return (
                  <AdvantagePowerHouse
                    data={data}
                    indexList={i}
                    id={dataKey}
                    key={`ppsHome-${dataKey}`}
                    pageType={pageType}
                  />
                )
              case 'ProductSeries':
                return (
                  <ProductSeries
                    data={data}
                    dataKey={dataKey + i}
                  ></ProductSeries>
                )
              case 'peaceOfMindTop':
                return (
                  <Advantage
                    indexList={i}
                    data={data}
                    id={dataKey}
                    key={`ppsHome-${dataKey}`}
                    pageType={pageType}
                  />
                )
              case 'news':
                return (
                  <News
                    data={data}
                    indexList={i}
                    id={dataKey}
                    key={`ppsHome-${dataKey}`}
                  />
                )
              case 'springSalePop':
                return (
                  <Pop
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    home
                  />
                )
              case 'SceneGuide':
                return <SceneGuide data={data} id={dataKey} key={dataKey} />
              case 'SellRecommend':
                return (
                  <SellRecommend
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'RightsArea':
                return (
                  <RightsArea
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'stayConnected':
                return (
                  <StayConnected
                    data={data}
                    id={dataKey}
                    pageHandle={pageHandle}
                    abtest={abtest}
                  />
                )
              case 'newBlogs':
                return (
                  <NewBlogs
                    data={data}
                    id={dataKey}
                    pageHandle={pageHandle}
                    abtest={abtest}
                  />
                )
              case 'Endorsement':
                return (
                  <Endorsement
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'BrandStory':
                return (
                  <BrandStory
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'energySaving':
                return (
                  <EnergySaving
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'Blogs':
                return (
                  <Blogs
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )
              case 'SoFunktioniert':
                return (
                  <SoFunktioniert
                    data={{
                      ...keysSnakeToCamel(shopCommon),
                      ...(data || {}),
                    }}
                    pageHandle={page?.handle}
                    id={dataKey}
                    key={dataKey}
                  />
                )

              case 'Firework':
                return <Firework data={data} s={s} />
            }
          })}
        </div>
      </div>
    </>
  )
}

export default HomePps
